/**
 * HeaderSearch
 */

import React, {useState, useEffect, useRef, useContext} from 'react';
import classNames from 'classnames';
import { httpGet } from 'utils/Http';
import { ucFirst } from 'utils/caseconverters';
import AppContext from 'Containers/Layouts/AppContext';
import AjaxLoader from 'Components/AjaxLoader';
import SearchAutocompleteResult from 'Components/SearchAutocompleteResult';
import { dataLayerPush } from 'utils/Datalayer';
import s from './HeaderSearch.module.scss';

const HeaderSearch = ({
    searchPageUrl,
    toggleSearchBarVisibility,
}) => {
    const {currentTheme} = useContext(AppContext);

    const autoCompleteRef = useRef();
    const searchFieldRef = useRef();

    const [query, setQuery] = useState('');
    const [fetching, setFetching] = useState(false);
    const [showNoResult, setShowNoResult] = useState(false);
    const [searchResult, setSearchResult] = useState({});
    const [typingTimeout, setTypingTimeout] = useState(0);

    useEffect(() => {
        document.addEventListener(
            'mousedown',
            handleClickOutsideAutoCompleteRef
        );

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideAutoCompleteRef
            );
        };
    }, []);

    const handleClickOutsideAutoCompleteRef = (e) => {
        if ((
            autoCompleteRef && autoCompleteRef.current &&
            searchFieldRef && searchFieldRef.current &&
            !autoCompleteRef.current.contains(e.target) &&
            !searchFieldRef.current.contains(e.target)
        ) && !e.target.classList.contains('HeaderMenu__Search')) {
            setSearchResult({});
            setShowNoResult(false);
            toggleSearchBarVisibility();
        }
    };

    const handleSearchFieldOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitOnClick();
        }

        if (e.key === 'Escape') {
            toggleSearchBarVisibility();
        }
    };

    const handleSearchFieldOnChange = (e) => {
        const value = e.currentTarget.value;

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            fetchAutoComplete(value);
        }, 500);

        setQuery(value);
        setSearchResult({});
        setTypingTimeout(newTimeout);
    };

    const fetchAutoComplete = (value) => {
        if (!value || !value.trim() || value.trim().length <= 2) {
            return;
        }

        const searchUrl = `/wt/api/v2/search/?query=${value}&limit=3`;

        setFetching(true);
        setShowNoResult(false);

        httpGet(searchUrl)
            .then((result) => {
                setFetching(false);
                setSearchResult(result);
                setShowNoResult(!result || !result.result.length);

                dataLayerPush({
                    'event': 'search',
                    'searchType': 'header',
                    'searchInput': value,
                    'hits': result && result.totalHits ? result.totalHits : 0,
                    'citySearch': '',
                    'searchCategory': '',
                });
            })
            .catch(() => {
                setFetching(false);
            });
    };

    const handleSubmitOnClick = () => {
        location.href = `${searchPageUrl}?query=${query}`;
    };

    const classes = classNames(
        s['HeaderSearch'],
        {[s[`HeaderSearch--${ucFirst(currentTheme)}`]]: currentTheme},
    );

    return (
        <div className={classes} id="header-search">
            <div className={s['HeaderSearch__Container']}>
                <div className={s['HeaderSearch__FieldContent']}>
                    <input
                        ref={searchFieldRef}
                        placeholder={'Vad letar du efter?'}
                        autoComplete="off"
                        autoFocus={true}
                        className={s['HeaderSearch__Field']}
                        value={query}
                        onKeyDown={(e) => handleSearchFieldOnKeyDown(e)}
                        onChange={(e) => handleSearchFieldOnChange(e)}
                        type="search"
                    />
                    <button
                        type="button"
                        className={s['HeaderSearch__Close']}
                        aria-label="Stäng"
                    />
                </div>

                {fetching && (
                    <div className={s['HeaderSearch__Fetching']}>
                        <AjaxLoader />
                    </div>
                )}

                <SearchAutocompleteResult
                    autoCompleteRef={autoCompleteRef}
                    searchPageUrl={searchPageUrl}
                    showNoResult={showNoResult}
                    context="header"
                    {...searchResult}
                />
            </div>
        </div>
    );
};

export default HeaderSearch;
