/**
 * NavMainContentItem
 */

import React from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import s from './NavMainContentItem.module.scss';

class NavMainContentItem extends React.Component {
    constructor(props) {
        super(props);
        const {activeMenuIds, page, expandedSubNav} = props;
        this.state = {
            expanded: activeMenuIds.includes(page.id),
            expandedSubNav: expandedSubNav,
        };
    }

    handleToggleExpandedOnClick = () => {
        const {expanded} = this.state;

        this.setState({
            expanded: !expanded,
            expandedSubNav: undefined,
        });
    }

    handleToggleExpandedSubNavOnClick = (value) => {
        const {expandedSubNav} = this.state;

        if (expandedSubNav === value) {
            this.setState({
                expandedSubNav: undefined,
            });
        } else {
            this.setState({
                expandedSubNav: value,
            });
        }
    }

    render() {
        const {page, items, id, externalLinkHref, externalLinkTitle, parentOpen, baseUrl} = this.props;
        const {expanded, expandedSubNav} = this.state;
        const isExternalLink = externalLinkHref && externalLinkTitle && Object.keys(page).length === 0;
        const href = isExternalLink ? externalLinkHref : page.href;
        const title = isExternalLink ? externalLinkTitle : page.title;

        return (
            <div
                className={classNames(
                    s['NavMainContentItem'],
                    {[s['NavMainContentItem--HasSubItems']]: items && !!items.length},
                    {[s['NavMainContentItem--Expanded']]: expanded},
                    {[s['NavMainContentItem--ParentOpen']]: parentOpen},
                )}
            >
                <div className={classNames(s['NavMainContentItem__ItemRow'], s['NavMainContentItem__ItemRow--FirstLevel'])}>
                    <a
                        className={classNames(
                            s['NavMainContentItem__Link'],
                            {[s['NavMainContentItem__Link--External']]: isExternalLink},
                        )}
                        rel={isExternalLink ? 'noopener noreferrer' : ''}
                        target={isExternalLink ? '_blank' : null}
                        href={href}
                    >{title}</a>

                    {!!items.length && (
                        <button
                            type="button"
                            aria-controls={`${id}-${href}`}
                            aria-label={expanded ? 'Stäng' : 'Öppna'}
                            onClick={this.handleToggleExpandedOnClick}
                            className={classNames(
                                s['NavMainContentItem__Expand'],
                                {[s['NavMainContentItem__Expand--Expanded']]: expanded},
                            )}
                        />
                    )}
                </div>

                {!!items.length && (
                    <AnimateHeight
                        duration={150}
                        animateOpacity={true}
                        height={expanded ? 'auto' : 0}
                    >
                        <ul id={`${id}-${href}`} className={classNames(
                            s['NavMainContentItem__List'],
                            {[s['NavMainContentItem__List--Expanded']]: expanded},
                        )}
                        >
                            {items.map((item, index) => (
                                <Item
                                    key={index}
                                    {...item}
                                    id={`${id}-sub-${index}`}
                                    expandedSubNav={expandedSubNav}
                                    baseUrl={baseUrl}
                                    handleClick={this.handleToggleExpandedSubNavOnClick}
                                />
                            ))}
                        </ul>
                    </AnimateHeight>
                )}
            </div>
        );
    }
}

NavMainContentItem.defaultProps = {
    page: {},
    items: [],
    activeMenuIds: [],
};

const Item = ({
    externalLinkHref = '',
    externalLinkTitle = '',
    page = {},
    items = [],
    id = 'nav-main-content-item-0',
    expandedSubNav = null,
    baseUrl = '',
    handleClick = () => {},
}) => {
    const isExternalLink = externalLinkHref &&
        externalLinkTitle && Object.keys(page).length === 0;
    const href = isExternalLink ? externalLinkHref : page.href;
    const title = isExternalLink ? externalLinkTitle : page.title;

    return (
        <li>
            <div className={s['NavMainContentItem__ItemRow']}>
                <a
                    target={isExternalLink ? '_blank' : null}
                    className={classNames(
                        s['NavMainContentItem__Link'],
                        s['NavMainContentItem__Link--Sub'],
                        {[s['NavMainContentItem__Link--External']]: isExternalLink},
                    )}
                    href={href}
                >{title}</a>

                {!!items.length && (
                    <button
                        type="button"
                        aria-controls={`${id}-${href}`}
                        onClick={() => handleClick(href)}
                        className={classNames(
                            s['NavMainContentItem__Expand'],
                            {[s['NavMainContentItem__Expand--Expanded']]: expandedSubNav === href},
                        )}
                    />
                )}
            </div>

            {!!items && (
                <AnimateHeight
                    duration={150}
                    animateOpacity={true}
                    height={expandedSubNav === href ? 'auto' : 0}
                >
                    <ul
                        id={`${id}-${href}`}
                        className={classNames(
                            s['NavMainContentItem__List'],
                            s['NavMainContentItem__List--Sub'],
                            {[s['NavMainContentItem__List--Expanded']]: expandedSubNav === href},
                        )}
                    >
                        {items.map((subNavItem, index) => {
                            const isExternalLink = !subNavItem.href.includes(baseUrl) && !subNavItem.href.startsWith('/');

                            const classes = classNames(
                                s['NavMainContentItem__Link'],
                                s['NavMainContentItem__Link--SubSub'],
                                {[s['NavMainContentItem__Link--External']]: isExternalLink},
                            );

                            return (
                                <li key={index}>
                                    <a
                                        target={isExternalLink ? '_blank' : null}
                                        className={classes}
                                        href={subNavItem.href}
                                    >
                                        {subNavItem.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </AnimateHeight>
            )}
        </li>
    );
};

export default NavMainContentItem;
