/**
 * NavMainContent
 */

import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import NavMainContentItem from '../NavMainContentItem';
import HeaderMenu from 'Components/HeaderMenu';
import Browsealoud from 'Components/Browsealoud';
import s from './NavMainContent.module.scss';

const NavMainContent = ({
    menu = [],
    open = false,
    modifier = '',
    searchPageUrl = '',
    activeMenuIds = [],
    expandedSubNav = null,
    browsealoudScript = '',
}) => {
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        let url = window.location.href;
        url = url.split('/')[2].split(':')[0];
        setBaseUrl(url);
    }, []);

    const classes = classNames(
        s['NavMainContent'],
        {[s['NavMainContent--Open']]: open},
        {[s[`NavMainContent--${modifier}`]]: modifier},
    );

    return (
        <div className={classes}>
            <div className={s['NavMainContent__Header']}>
                <HeaderMenu
                    disableHeadroom={true}
                    disableGoogleTranslate={true}
                    searchPageUrl={searchPageUrl}
                    menu={menu}
                />
                {menu && !!menu.length && (
                    <ul className={s['NavMainContent__List']}>
                        <div className={s['NavMainContent__InnerWrapper']}>
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <NavMainContentItem
                                        {...item}
                                        id={`nav-main-item-${index}`}
                                        activeMenuIds={activeMenuIds}
                                        expandedSubNav={expandedSubNav}
                                        parentOpen={open}
                                        baseUrl={baseUrl}
                                    />
                                </li>
                            ))}
                            {browsealoudScript &&
                                <Browsealoud
                                    browsealoudScript={browsealoudScript}
                                    modifier={'Mobile'}
                                />
                            }
                        </div>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default NavMainContent;
